/* eslint-disable camelcase */
import { clientEnv } from 'config/env';
import { createNewsletterSignupFormFields } from 'app/utilities/newsletter-form-fields';
import { createValidationDataObject } from 'app/utilities/form-validation';
import { Link } from 'react-router-dom';
import Loader from 'app/components/partials/loader';
import Modal from 'app/components/partials/modal';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

const NewsletterSignup = ({ getFieldTouched, getFieldValue, setFieldValue, touchedField, submitForm, submittedSuccessfully, resetForm, error }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    // Handlers
    const handleSubmit = (event) => {
        event.preventDefault();
        setIsLoading(true);

        // eslint-disable-next-line no-undef
        grecaptcha.ready(function() {
            // eslint-disable-next-line no-undef
            grecaptcha.execute(clientEnv.RECAPTCHA_KEY, { action: 'newsletter' }).then(function(token) {
                submitForm({
                    data: {
                        first_name: getFieldValue('firstname'),
                        last_name: getFieldValue('lastname'),
                        email: getFieldValue('email'),
                        captchaToken: token
                    },
                    setIsLoading
                });
            });
        });
    };

    const handlePreSubmit = (event) => {
        event.preventDefault();
        setIsModalOpen(true);
    };

    // Renders
    const renderField = ({ name, placeholder, type, validator, fieldToCompare = '' }) => {
        const touched = getFieldTouched(name);
        const value = getFieldValue(name);
        let validationData;

        if (!touched) {
            validationData = createValidationDataObject();
        } else if (fieldToCompare) {
            validationData = validator(value, getFieldValue(fieldToCompare));
        } else {
            validationData = validator(value);
        }

        return (
            <div className="newsletter-field" key={name}>
                <input
                    name={name}
                    type={type}
                    className={`newsletter-input ${validationData.valid ? '' : 'is-invalid'}`}
                    value={value}
                    onChange={(event) => {
                        setFieldValue(name, event.target.value);
                        touchedField(name);
                    }}
                    placeholder={placeholder}
                    onBlur={() => touchedField(name)}
                />
                {!validationData.valid && (
                    <p className="newsletter-validation">{validationData.message}</p>
                )}
            </div>
        );
    };

    const renderSubmitButton = (mode) => {
        const formInvalid = createNewsletterSignupFormFields(mode).some(({ name, validator, fieldToCompare }) => {
            if (fieldToCompare) {
                return !validator(getFieldValue(name), getFieldValue(fieldToCompare)).valid;
            }

            return !validator(getFieldValue(name)).valid;
        });

        return (
            <button
                type="submit"
                className="button small"
                disabled={formInvalid}
            >Sign up</button>
        );
    };

    const renderSubmitSection = (mode) => {
        if (isLoading) {
            return (
                <button type="button" className="button small" disabled>
                    <Loader type="small" />
                </button>
            );
        }

        return renderSubmitButton(mode);
    };

    const renderSignupForm = (mode = '') => {
        return (
            <form
                className={`newsletter ${mode === 'modal' ? 'newsletter-modal' : ''}`}
                role="group"
                action="#"
                method="POST"
                onSubmit={mode === 'modal' ? handleSubmit.bind(this) : handlePreSubmit.bind(this)}
            >
                {createNewsletterSignupFormFields(mode).map(renderField.bind(this))}
                {error && <p className="newsletter-validation error">Sorry, we didn’t get your message. Try again later.</p>}
                {mode === 'modal' && (
                    <div>
                        <p className="terms-and-privacy">We manage subscriber name and email details in accordance with our
                            <Link to="/terms-and-privacy-policy" target="_blank">
                                Privacy Policy
                            </Link>
                        </p>
                    </div>
                )}
                {renderSubmitSection(mode)}
            </form>
        );
    };

    const hideModal = () => {
        resetForm();
        setIsModalOpen(false);
    };

    const renderModal = () => {
        let message = '',
            title = 'Sign up to our newsletter';

        // Display successful message
        if (submittedSuccessfully) {
            title = 'Thanks for signing up';
            message = 'We’ll be sharing the latest news from the Zoo. You can change your email preferences at any time by following the link at the bottom of our newsletters.';
        }

        // Display error message
        if (error) {
            title = 'Oops! Something went wrong';
            message = 'Please try again later.';
        }

        return (
            <Modal isVisible={isModalOpen} onCloseHandler={() => hideModal()}>
                <div>
                    <h3>{title}</h3>
                    {message && <p>{message}</p>}
                    {submittedSuccessfully || error ? (
                        <button
                            className="button primary"
                            type="button"
                            onClick={() => hideModal()}
                        >OK</button>) : renderSignupForm('modal')
                    }
                </div>
            </Modal>
        );
    };

    return (
        <>
            {renderSignupForm()}
            {renderModal()}
            <p className="terms-and-privacy">We manage subscriber name and email details in accordance with our
                <Link to="/terms-and-privacy-policy">
                    Privacy Policy
                </Link>
            </p>
        </>
    );
};

NewsletterSignup.propTypes = {
    error: PropTypes.bool.isRequired,
    getFieldTouched: PropTypes.func.isRequired,
    getFieldValue: PropTypes.func.isRequired,
    isBusy: PropTypes.bool.isRequired,
    resetForm: PropTypes.func.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    submitForm: PropTypes.func.isRequired,
    submittedSuccessfully: PropTypes.bool,
    touchedField: PropTypes.func.isRequired,
};

export default NewsletterSignup;
